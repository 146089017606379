import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ServicesPage = () => (
    <Layout>
        <SEO title="Contacto" />
        <div className="inner-banner">
            <div className="container">
                <div className="inner-title text-center">
                    <h3>Servicios</h3>
                    <ul>
                        <li>
                            <a href="/">Inicio</a>
                        </li>
                        <li>
                            <i className="bx bx-chevron-right"></i>
                        </li>
                        <li>Servicios</li>
                    </ul>
                </div>
            </div>
            <div className="inner-banner-shape">
                <div className="shape-one">
                    <StaticImage src="../images/inner-banner/banner-shape1.png" alt="Images"/>
                </div>
                <div className="shape-two">
                    <StaticImage src="../images/inner-banner/banner-shape2.png" alt="Images"/>
                </div>
                <div className="shape-three">
                    <StaticImage src="../images/inner-banner/banner-shape3.png" alt="Images"/>
                </div>
                <div className="inner-banner-dots-2">
                    <StaticImage src="../images/shape/dots-shape.png" alt="Images"/>
                </div>
            </div>
        </div>

        <div className="service-widget-area pt-50 pb-70">
            <div className="container">
                <div className="section-title text-center">
                    <span className="sp-before sp-after">Servicios</span>
                    <h2 className="h2-color">Tecnología de calidad <b>para nuestros clientes</b></h2>
                </div>
                <div className="row pt-45">
                    <div className="col-lg-4 col-md-6">
                        <div className="service-card">
                            <a href="/service-details/">
                                <StaticImage src="../images/service/service-icon1.png" alt="Images"/>
                            </a>
                            <h3><a href="/service-details/">Apliaciones móviles</a></h3>
                            <p>
                                Prototipamos y desarrollamos aplicaciones móviles según sus requerimientos
                            </p>
                            <div className="service-card-shape">
                                <StaticImage src="../images/service/service-shape.png" alt="Images"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-card">
                            <a href="/service-details/">
                                <StaticImage src="../images/service/service-icon2.png" alt="Images"/>
                            </a>
                            <h3><a href="/service-details/">Automatización de procesos</a></h3>
                            <p>
                                Reducimos costos mediante la tecnología.
                            </p>
                            <div className="service-card-shape">
                                <StaticImage src="../images/service/service-shape.png" alt="Images"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-card">
                            <a href="/service-details/">
                                <StaticImage src="../images/service/service-icon3.png" alt="Images"/>
                            </a>
                            <h3><a href="/service-details/">Analítica de datos</a></h3>
                            <p>
                                Construimos modelos de recomendación, tableros avanzados y descubrimos lo que está detrás de sus datos.
                            </p>
                            <div className="service-card-shape">
                                <StaticImage src="../images/service/service-shape.png" alt="Images"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-card">
                            <a href="/service-details/">
                                <StaticImage src="../images/service/service-icon4.png" alt="Images"/>
                            </a>
                            <h3><a href="/service-details/">Internet de las cosas</a></h3>
                            <p>
                                Desarrollamos proyectos que incluyen sensores y redes de largo alcance y bajo costo.
                            </p>
                            <div className="service-card-shape">
                                <StaticImage src="../images/service/service-shape.png" alt="Images"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-card">
                            <a href="/service-details/">
                                <StaticImage src="../images/service/service-icon5.png" alt="Images"/>
                            </a>
                            <h3><a href="/service-details/">Desarrollo web</a></h3>
                            <p>
                                Llevamos a la realidad proyectos con alcances complejos en tecnologías como React JS y Angular.
                            </p>
                            <div className="service-card-shape">
                                <StaticImage src="../images/service/service-shape.png" alt="Images"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-card">
                            <a href="/service-details/">
                                <StaticImage src="../images/service/service-icon6.png" alt="Images"/>
                            </a>
                            <h3><a href="/service-details/">Consultoría</a></h3>
                            <p>
                                Contamos con la experiencia para acompañarlo en su tranformación digital usando las herramientas adecuadas y en tiempo récord.
                            </p>
                            <div className="service-card-shape">
                                <StaticImage src="../images/service/service-shape.png" alt="Images"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="brand-logo-area  pt-100">
            <div className="container-fluid">
                <div className="container-max">
                    <div className="brand-logo-slider owl-carousel owl-theme">
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo1.png" alt="Images"/>
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo2.png" alt="Images"/>
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo3.png" alt="Images"/>
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo4.png" alt="Images"/>
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo5.png" alt="Images"/>
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo6.png" alt="Images"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
)

export default ServicesPage
